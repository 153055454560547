@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,400;1,200&display=swap");
@import url('https://fonts.googleapis.com/css2?&family=Roboto+Mono:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');



@font-face {
    font-family: "panic";
    src: url("../public/fonts/Panic.ttf");
}

@font-face {
    font-family: "drglitch";
    src: url("../public/fonts/Doctor Glitch.otf");
}
@font-face {
    font-family: "led";
    src: url("../public/fonts/LEDLIGHT.otf");
}
@font-face {
    font-family: "fragment";
    src: url("../public/fonts/Fragmentcore.otf");
}
@font-face {
    font-family: "blueocean";
    src: url("../public/fonts/BLUE OCEAN.ttf");
}
@font-face {
    font-family: "gau";
    src: url("../public/fonts/GAU_pop_magic.TTF");
}
@font-face {
    font-family: "glitchgitch";
    src: url("../public/fonts/gitchgitch.otf");
}
@tailwind base;
@tailwind components;
@tailwind utilities;


